class ZIDXAccountRemapListingIndexSelect implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountRemapListingIndexSelectContainer";
    }
    getMatchURL(){
        return "/account/data-feeds/listing-index/remap-data/select";
    }
    render(){
        // console.log("listing index remap select");
    }
}